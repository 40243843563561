$theme-colors: (
  "primary": #EE3423, // Red
  "secondary": #000 // Black
);

$font-family-sans-serif: "Arial";

@import "bootstrap/bootstrap.scss";

body {
  background: {
		attachment: fixed;
		position: center 0;
		color: #9d9d9d;
		size: auto;
	};
	@include media-breakpoint-up(xl) { background-size: 100% auto; }
}

#logo {
	width: auto;
	height: 40px;
	@include media-breakpoint-up(md) {
		height: 67px;
	}
}

.upcase {
  text-transform: uppercase;
}

.nav-link {
	font-size: 18px;
	font-weight: bold;
	color: map-get($theme-colors, secondary) !important;
	&:hover,
	&.active {
		color: map-get($theme-colors, primary) !important;
	}
}

.current {
	color: green;
}

.intro {
  	display: flex;
  	align-items: center;
  	min-height: 400px;
}

.preserve-line-breaks {
  	white-space: pre;
}

.image {
  	width: 100%;
  	height: auto;
  	filter: grayscale(100%);
}

#image {
	width: 100%;
	max-width: 450px;
	max-height: 300px;
}

.card-hover {
	&:hover {
		border-color: map-get($theme-colors, primary);
		img {
			filter: grayscale(0%);
		}
	}
}

.mugshot {
  	width: 200px;
  	height: 144px;
  	background: #CCC;
}

img#history {
	width: 100%;
	@include media-breakpoint-down(md) {
		width: 100%;
	}	
}

#map {
	width: 100%;
	height: 600px;
	background: {
		position: 50% 50%;
		image: url('/workspace/images/map.png');
		color: #808080;
		repeat: no-repeat;
		size: auto 140%;
	}
}

ul {
  	padding: 0;
}

.flag {
  	width: 60px;
  	height: 60px;
}

.icon {
	&:hover {
		text-decoration: none !important;
		i {
			opacity: 0.8;
		}
	}
}

.seco {
	color: map-get($theme-colors, primary);
	font-weight: bold;	
}

.text-shadow {
	text-shadow: 1px 1px 1px rgba(0,0,0,0.5);
}
